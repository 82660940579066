import MediaService from '../../services/media/MediaService'
import DamService from '../../services/dam/DamService'
import DamPdfService from '../../services/dam/DamPdfService'
import { AVAILABLE_SOURCES } from '@/model/ValueObject/DamUploadSources'
import NotifyService from '@/services/NotifyService'

const MEDIA_SELECTED_CLASSNAME = 'media-item-selected'

export default {
  data () {
    return {
      downloading: false
    }
  },
  methods: {
    getMediaUrl (widenId, width = '', height = '', position = '') {
      return MediaService.getMediaUrl(widenId, width, height, position)
    },
    getDamImagePath (basename, width = 800, height = '') {
      return DamService.getImagePath(basename, width, height)
    },
    getDamPdfPath (basename) {
      return DamPdfService.getPdfPath(basename)
    },
    getDamPdfPreviewPath (basename) {
      return DamPdfService.getPdfPreviewPath(basename)
    },
    getReferenceAssetPreviewUrl (asset) {
      return this.isAssetImage(asset) ? this.getDamImagePath(asset.basename) : this.getDamPdfPreviewPath(asset.basename)
    },
    isAssetImage (asset) {
      return AVAILABLE_SOURCES.findIndex(x => x.id === asset.uploadSource) !== -1
    },
    downloadMedia (media) {
      this.$store.dispatch('dam/fetchOne', media.damMediaEmbed.damId)
        .then(() => {
          this.downloadAsset(this.$store.getters['dam/detail'])
        })
        .catch(() => {
          this.downloading = false
        })
    },
    downloadAsset (asset) {
      if (this.loading) {
        return
      }
      this.downloading = true
      this.$store.dispatch('dam/download', asset)
        .then(() => {
          this.downloading = false
        })
        .catch(() => {
          this.downloading = false
        })
    },
    downloadAssets (assets) {
      if (this.loading) {
        return
      }
      this.downloading = true
      const assetIds = assets.map(asset => asset.id)
      this.$store.dispatch('dam/downloadZip', { assetDocuments: assetIds })
        .then(() => {
          this.downloading = false
        })
        .catch(() => {
          this.downloading = false
        })
    },
    toggleSelectionMode (event) {
      this.selectedAssets = []
      if (this.selectionMode) {
        this.selectionMode = false
        event.target.classList.remove('btn-dark')
        event.target.classList.add('btn-info')
      } else {
        this.selectionMode = true
        event.target.classList.remove('btn-info')
        event.target.classList.add('btn-dark')
      }
    },
    addSelectedAsset (asset) {
      this.selectedAssets.push(asset)
    },
    removeSelectedAsset (asset) {
      this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1)
    },
    toggleSelection (event, asset, maxSelectedItems) {
      if (this.selectionMode === false) {
        return
      }
      if (event.target.classList.contains(MEDIA_SELECTED_CLASSNAME)) {
        event.target.classList.remove(MEDIA_SELECTED_CLASSNAME)
        this.removeSelectedAsset(asset)
      } else if (this.selectedAssets.length < maxSelectedItems) {
        event.target.classList.add(MEDIA_SELECTED_CLASSNAME)
        this.addSelectedAsset(asset)
      }
    },
    cancelSelection () {
      this.selectedAssets = []
      const selectedElements = document.querySelectorAll('.media-item.' + MEDIA_SELECTED_CLASSNAME)
      for (let i = 0; i < selectedElements.length; i++) {
        selectedElements[i].classList.remove(MEDIA_SELECTED_CLASSNAME)
      }
    },
    async originalUrlToClipboard (asset) {
      await navigator.clipboard.writeText(DamPdfService.getPdfPath(asset.basename))
      NotifyService.setInfoMessage(this.$i18n.t('notify.url_was_copied'))
    }
  }
}
