export const SOURCE_EAGLE_IMAGE = 'eagle_image'
export const SOURCE_EAGLE_URL = 'eagle_url'
export const SOURCE_FTP = 'ftp'
export const SOURCE_DAM_IMAGE = 'dam_image'
export const SOURCE_DAM_URL = 'dam_url'
export const SOURCE_OTHER = 'other'
export const SOURCE_MIGRATION_EAGLE = 'eagle_mig'
export const SOURCE_MIGRATION_WEBJET = 'wj_mig'
export const SOURCE_MIGRATION_WIDEN = 'widen_mig'
export const SOURCE_MIGRATION_RAS = 'ras_mig'
export const SOURCE_DAM_PDF = 'dam_pdf'

export const SOURCE_MAP = {
  eagle_image: 'Eagle Image',
  eagle_url: 'Eagle URL',
  ftp: 'Fotografi (FTP)',
  dam_image: 'Dam image',
  dam_url: 'Dam URL',
  other: 'Other',
  eagle_mig: 'Eagle migration',
  wj_mig: 'WebJET migration',
  widen_mig: 'Widen migration'
}

export const AVAILABLE_SOURCES = [
  {
    id: SOURCE_EAGLE_IMAGE,
    title: 'Article Image'
  },
  {
    id: SOURCE_EAGLE_URL,
    title: 'Eagle URL'
  },
  {
    id: SOURCE_FTP,
    title: 'Fotografi (FTP)'
  },
  {
    id: SOURCE_DAM_IMAGE,
    title: 'Dam image'
  },
  {
    id: SOURCE_DAM_URL,
    title: 'Dam URL'
  },
  {
    id: SOURCE_OTHER,
    title: 'Other'
  },
  {
    id: SOURCE_MIGRATION_EAGLE,
    title: 'Eagle migration'
  },
  {
    id: SOURCE_MIGRATION_WEBJET,
    title: 'WebJET migration'
  },
  {
    id: SOURCE_MIGRATION_WIDEN,
    title: 'Widen migration'
  },
  {
    id: SOURCE_MIGRATION_RAS,
    title: 'RAS migration'
  }

]

export const AVAILABLE_PDF_SOURCES = [
  {
    id: SOURCE_FTP,
    title: 'FTP'
  },
  {
    id: SOURCE_DAM_PDF,
    title: 'Dam upload'
  },
  {
    id: SOURCE_DAM_URL,
    title: 'Dam URL'
  },
  {
    id: SOURCE_OTHER,
    title: 'Other'
  }
]
