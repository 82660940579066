import CoreApi from '../../api/core'
import Config from '../../config/'
import DamApi from '../../api/dam'
import MediaService from './MediaService'

/**
 * Images are uploaded to DAM by chunks of 5 images to prevent overloading of the server.
 */
const DAM_UPLOAD_CHUNK_SIZE = 5

const findOneBy = function (id, callback) {
  return CoreApi().get('/media/' + id)
    .then(response => callback(response.status, response.data))
    .catch(error => console.log(error))
}

const getMediaUrl = function (widenId, width = '130', height = '', position = 'c', quality = 74) {
  let size = width
  if (width !== '' && height !== '') {
    size += 'x' + height
  }
  size += 'px'
  return Config.mediaUrl + '/' + widenId + '/' + size + '/?position=' + position + '&quality=' + quality
}

const dataUriToBlob = function (dataURI) {
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

const mapDamAssetToCoreMedia = (asset) => {
  let imageCaption = asset.metadata.description
  if (imageCaption === '') {
    imageCaption = asset.metadata.caption
  }
  if (imageCaption === '') {
    imageCaption = asset.metadata.headline
  }
  return {
    damMediaEmbed: {
      damId: asset.id,
      filename: asset.basename
    },
    imageCaption: imageCaption,
    imageAttribution: asset.metadata.byLine,
    hideCaption: false,
    exportWoodWing: false,
    size: asset.size,
    dimensions: {
      width: asset.dimensions.width,
      height: asset.dimensions.height
    }
  }
}

const createMedia = async (media) => {
  const damAsset = (await DamApi().get(`asset/${media.damMediaEmbed.damId}`)).data
  const mediaToPost = mapDamAssetToCoreMedia(damAsset)
  mediaToPost.imageCaption = media.imageCaption
  mediaToPost.imageAttribution = media.imageAttribution
  const response = await CoreApi().post('/media', mediaToPost)
  return response.data
}

const createMediaFromUrl = async (url) => {
  return new Promise((resolve, reject) => {
    DamApi().post('/metadata/image/url', { url: url })
      .then((response) => {
        const file = {
          fileBase64: response.data.base64Image,
          metadata: {
            caption: response.data.metadata.caption,
            author: response.data.metadata.byLine,
            keywords: response.data.metadata.keywords
          }
        }
        uploadFile(file)
          .then((response) => {
            resolve({
              data: response.data
            })
          })
      })
      .catch(error => {
        console.log(error)
        reject(new Error(error))
      })
  })
}

const uploadFile = async (file) => {
  return new Promise((resolve, reject) => {
    const bodyFormData = new FormData()
    bodyFormData.set('image', MediaService.dataUriToBlob(file.fileBase64))
    bodyFormData.set('caption', file.metadata.caption)
    bodyFormData.set('byLine', file.metadata.author)
    bodyFormData.set('keywords', file.metadata.keywords)
    CoreApi().post('/dam/upload', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve({
          data: response.data
        })
      })
      .catch(error => {
        console.log(error)
      })
  })
}

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  findOneBy,
  getMediaUrl,
  dataUriToBlob,
  mapDamAssetToCoreMedia,
  createMedia,
  createMediaFromUrl,
  toBase64,
  DAM_UPLOAD_CHUNK_SIZE
}
